<template>
  <highcharts
    :options="chartOptions"
    ref="chart"
  />
</template>

<script>
import { Chart } from 'highcharts-vue';
import BaseChart from "@/mixins/Chart";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { mapGetters } from 'vuex'

HighchartsMore(Highcharts);

export default {
  name: "PieChartComponent",
  mixins: [BaseChart],
  components: {
    highcharts: Chart
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    },
    config: {
      type: Object,
      default: function () {
        return {
          showLegend: false,
        };
      },
    },
  },
  data() {
    return {
      chartRef: null,
      dataCollection: {},
      options: {},
      chartOptions: {
        credits: {
          enabled: false
        },
        colors: this.chartColors,
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: this.height
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          pointFormat: '{series.name}'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: this.enabledLegend,
              format: '<b>{point.name}</b>'
            },
            colors: this.chartColors
          }
        },
        series: this.buildSeriesData,
        responsive: {
          rules: [{
            condition: {
              maxWidth: this.width,
              maxHeight: this.height
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
              }
            }
          }]
        }
      },
      chartStyles: {
        width: this.width,
        height: this.height,
      },
    };
  },
  computed: {
    ...mapGetters({
      dashboardFilters: 'reporting/filters'
    }),
    enabledLegend () {
      return this.config.showLegend
    },
    buildSeriesData() {
      let data = this.getCData
      if (data.values.length === 1) {
        let seriesData = []
        data.labels.forEach((label, index) => {
          seriesData.push({
            name: label,
            y: data.values[0][index],
          })
        });
        return [{
          name: this.chartTitle,
          colorByPoint: true,
          data: seriesData
        }]
      }
      let seriesData = []
      data.names.forEach((item, i) => {
        seriesData.push({
          name: item,
          y: data.values[i].reduce((a, b) => a + b, 0)
        });
      })

      return [{
        name: this.chartTitle,
        colorByPoint: true,
        data: seriesData
      }];
    },
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    },
    updateChart () {
      this.chartOptions = {
        credits: {
          enabled: false
        },
        colors: this.chartColors,
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: this.height
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          pointFormat: '{series.name}: {point.y}'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: this.enabledLegend,
              format: '<b>{point.name}</b>',
              colors: this.chartColors
            }
          }
        },
        series: this.buildSeriesData,
        responsive: {
          rules: [{
            condition: {
              maxWidth: this.width,
              maxHeight: this.height
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
              }
            }
          }]
        }
      };
      this.chartRef.update(this.chartOptions);
    }
  },
  mounted() {
    this.chartRef = this.$refs.chart.chart;
    this.updateChart();
  },
  watch: {
    config: {
      handler: function (newVal, oldVal) {
        this.updateChart();
      },
      deep: true
    }
  }
};
</script>