<template>
  <highcharts :options="chartOptions" />
</template>
<script>
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

export default {
  name: "RadialChartComponent",
  components: {
    highcharts: Chart
  },
  props: {
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    },
    labelFontSize: {
      type: String,
      default: "10px"
    },
    config: {
      type: Object,
      default: function () {
        return {
          showLegend: false,
          showXaxisLabel: true,
          showXgridLines: false,
          showYaxisLabel: false,
          showYgridLines: false,
          showDataLabels: false,
          showDataLabelsInside: false
        }
      }
    }
  },
  data() {
    return {
      chartOptions: {},
      gridLineWidthX: 0,
      gridLineWidthY: 0
    }
  },
  created() {
    this.gridLineWidthX = this.config.showXgridLines ? 1 : 0;
    this.gridLineWidthY = this.config.showYgridLines ? 1 : 0;
  },
  mounted () {
    this.chartOptions = {
      chart: {
        type: 'column',
        polar: true,
        inverted: true,
        height: this.height,
        width: this.width
      },
      colors: ['blue', 'green', 'red', 'orange'],
      title: {
        text: null
      },
      legend: {
        enabled: this.config.showLegend
      },
      pane: {
          size: '80%',
          innerSize: '20%',
          //endAngle: 300
      },
      xAxis: {
        categories: ['Data1', 'Data2', 'Data3', 'Data4'],
        gridLineWidth: this.gridLineWidthX,
        tickWidth: 0,
        lineWidth: this.gridLineWidthX,
        labels: {
          enabled: this.config.showXaxisLabel,
          style: {
            fontSize: this.labelFontSize
          }
        }
      },
      yAxis: {
        max: 100,
        showLastLabel: false,
        showFirstLabel: false,
        lineWidth: this.gridLineWidthY,
        gridLineWidth: this.gridLineWidthY,
        labels: {
          enabled: this.config.showYaxisLabel
        }
      },
      plotOptions: {
        series: {
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: this.config.showDataLabels,
            inside: this.config.showDataLabelsInside,
            allowOverlap: true
          }
        }
      },
      series: [{
        colorByPoint: true,
        data: [75, 50, 40, 20]
      }],
      credits: {
        enabled: false
      }
    }
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    }
  }
}
</script>