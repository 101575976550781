<template>
  <VContainer
    pa-0
    id="radial-settings-container"
  >
    <VRow no-gutters>
      <VCol>
        <VSubheader>X-axis</VSubheader>
      </VCol>
    </VRow>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Show axis label
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showXaxisLabel" />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        class="pr-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Show Gridlines
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showXgridLines" />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <div style="background-color: rgb(226 226 226)">
      <VRow no-gutters>
        <VCol>
          <VSubheader>Y-axis</VSubheader>
        </VCol>
      </VRow>
      <VRow class="pl-7 pr-7">
        <VCol
          md="6"
          pl-4
          class="pl-0 pt-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions style="height: 40px;">
              <VSubheader class="pa-2">
                Show axis label
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showYaxisLabel" />
            </VCardActions>
          </VCard>
        </VCol>
        <VCol
          md="6"
          pl-4
          class="pr-0 pt-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions style="height: 40px;">
              <VSubheader class="pa-2">
                Show Gridlines
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showYgridLines" />
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
    </div>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        pl-4
        class="pl-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Show Legend
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showLegend" />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        pl-4
        class="pr-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Show Data Labels
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showDataLabels" />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        pl-4
        class="pl-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Data Labels Position (Inside)
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showDataLabelsInside" />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </Vcontainer>
</template>
<script>
export default {
  name: "RadialChartSettings",
  props: {
    defaultSettings: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      settings: {},
    };
  },
  created() {
    this.settings = { ...this.defaultSettings, cols: 3 };
  },
  methods: {
    chartSetting() {
      this.$emit("setSettings", JSON.parse(JSON.stringify(this.settings)));
    },
  },
};
</script>