<template>
  <line-chart
    :chart-data="dataCollection"
    :styles="chartStyles"
    :options="chartOptions"
  />
</template>

<script>
import LineChart from "../config/LineChart";

export default {
  name: "SegmentChartComponent",
  components: {
    LineChart,
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    },
    config: {
      type: Object,
      default: function () {
        return {
          isFill: false,
          fillColor: "#ffffff",
          pointColor: "#0092f9",
          showXaxisLabel: true,
          showXgridLines: true,
          showYaxisLabel: true,
          showYgridLines: true,
          showYscaleLabel: true,
          showLegend: false,
        };
      },
    },
  },
  data() {
    return {
      dataCollection: {},
      chartOptions: {},
      chartStyles: {
        width: this.width,
        height: this.height,
      },
    };
  },
  mounted() {
    this.dataCollection = {
      labels: ["DATA1", "DATA2", "DATA3", "DATA4", "DATA5", "DATA6", "DATA7"],
      datasets: [
        {
          data: [40, 50, 70, 55, 55, 90, 40],
          fill: this.config.isFill,
          backgroundColor: this.config.fillColor,
          pointBackgroundColor: this.config.pointColor,
          pointRadius: 4,
          showLine: this.config.isFill,
        },
      ],
    };
    this.chartOptions = {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: this.config.showXgridLines,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              display: this.config.showXaxisLabel,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              display: this.config.showYaxisLabel,
            },
            gridLines: {
              display: this.config.showYgridLines,
            },
            scaleLabel: {
              display: this.config.showYscaleLabel,
              labelString: "NUMBER OF RECORDS",
              fontSize: 8,
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: this.config.showLegend,
      },
    };
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    },
  },
};
</script>