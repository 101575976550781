<template>
  <highcharts :options="chartOptions" />
</template>
<script>
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'
import bulletInit from 'highcharts/modules/bullet'

bulletInit(Highcharts);

export default {
  name: "FullMeterComponent",
  components: {
    highcharts: Chart
  },
  props: {
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    },
    showLegend: {
      type: Boolean,
      default: false
    },
    minYaxis: {
      type: Number,
      default: 0
    },
    maxYaxis: {
      type: Number,
      default: 200
    },
    tickInerval: {
      type: Number,
      default: 50
    },
    bulletLength: {
      type: Number,
      default: 150
    },
    config: {
      type: Object,
      default: function () {
        return {
          showLegend: false,
          plotColor: "#518ff5",
          prefixYlabel: "$",
          postfixYlabel: "k",
          bulletColor: "#ffffff"
        }
      }
    }
  },
  data() {
    return {
      chartOptions: {}
    }
  },
  created() {
    this.config.prefixYlabel = this.config.prefixYlabel ? this.config.prefixYlabel : "";
    this.config.postfixYlabel = this.config.postfixYlabel ? this.config.postfixYlabel : "";
  },
  mounted () {
    this.chartOptions = {
      chart: {
        type: 'bullet',
        inverted: false,
        height: this.height,
        width: this.width,
      },
      title: {
        text: null
      },
      legend: {
        enabled: this.config.showLegend
      },
      xAxis: {
        categories: [''],
      },
      yAxis: {
        plotBands: [{
          from: this.minYaxis,
          to: this.maxYaxis,
          color: this.config.plotColor
        }],
        labels: {
          format: this.config.prefixYlabel + '{value}' + this.config.postfixYlabel
        },
        title: null,
        gridLineWidth: 0,
        opposite: true,
        min: this.minYaxis,
        tickInterval: this.tickInerval,
        offset: 10,
        tickWidth: 1,
        minorGridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickLength: 5,
        minorTickWidth: 1
      },
      series: [{
        type: "bullet",
        data: [{
          y : this.bulletLength,
        }],
      }],
      plotOptions: {
        series: {
          pointPadding: 0.4,
          borderWidth: 0,
          borderRadius: 5,
          color: this.config.bulletColor
        }
      },
      credits: {
        enabled: false
      }
    }
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    }
  }
};
</script>