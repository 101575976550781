<template>
  <bar-chart
    :width="150"
    :chart-data="dataCollection"
    :options="chartOptions"
    :styles="chartStyles"
  />
</template>

<script>
import Chart from "@/mixins/Chart";
import BarChart from "../config/BarChart";

export default {
  name: "HistogramComponent",
  components: {
    BarChart,
  },
  mixins: [Chart],
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    },
    config: {
      type: Object,
      default: function () {
        return {
          showXaxisLabel: true,
          showXgridLines: false,
          showYaxisLabel: true,
          showYgridLines: true,
          showLegend: false,
        };
      },
    },
  },
  data() {
    return {
      sampleDataCollection: {
        labels: [
          "DATA1",
          "DATA2",
          "DATA3",
          "DATA4",
          "DATA5",
          "DATA6",
          "DATA7",
          "DATA8",
        ],
        datasets: [
          {
            backgroundColor: [
              "#7479db",
              "#dd475f",
              "#508ef8",
              "#80e198",
              "#eaae31",
              "#ea7731",
              "#ea7731",
              "#ec57b9",
            ],
            data: [40, 50, 70, 55, 55, 90, 40, 55],
            barThickness: 20,
          },
        ],
      },
    };
  },
  computed: {
    dataCollection() {
      return {
        dataset: this.preparedData.data || this.sampleDataCollection.datasets,
        labels: this.preparedData.labels || this.sampleDataCollection.labels,
      };
    },
    chartOptions() {
      return {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: this.config.showXgridLines,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                display: this.config.showXaxisLabel,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: this.config.showYgridLines,
              },
              ticks: {
                beginAtZero: true,
                stepSize: 20,
                display: this.config.showYaxisLabel,
              },
              scaleLabel: {
                display: false,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          labels: false,
        },
        legend: {
          display: this.config.showLegend,
        },
      };
    },
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    },
  },
};
</script>