<template>
  <VContainer
    pa-0
    id="donut-settings-container"
  >
    <VRow class="pl-7 pr-7 pt-7">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Show Legend
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showLegend" />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        class="pr-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Legend Position
            </VSubheader>
            <VSpacer />
            <VCol class="pa-0">
              <VSelect
                :items="legendPositions"
                v-model="settings.legendPosition"
                dense
                solo
              />
            </VCol>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="pl-7 pr-7 pt-3">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Labels Position
            </VSubheader>
            <VSpacer />
            <VCol class="pa-0">
              <VSelect
                :items="labelsPosition"
                v-model="settings.labelsPosition"
                dense
                solo
              />
            </VCol>
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        class="pr-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Labels Alignment
            </VSubheader>
            <VSpacer />
            <VCol class="pa-0">
              <VSelect
                :items="labelsAlignment"
                v-model="settings.labelsAlignment"
                dense
                solo
              />
            </VCol>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        class="pl-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Labels Color
            </VSubheader>
            <VSpacer />
            <Swatches
              v-model="settings.labelsColor"
              show-fallback
              swatches="text-advanced"
              class="pt-1"
            />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        class="pr-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Show Center Text
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showText" />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </Vcontainer>
</template>
<script>
import Swatches from "vue-swatches";

export default {
  name: "PieChartSettings",
  components: {
    Swatches,
  },
  props: {
    defaultSettings: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      settings: {},
      legendPositions: ["top", "left", "bottom", "right"],
      labelsPosition: ["center", "start", "end"],
      labelsAlignment: [
        "top",
        "left",
        "bottom",
        "right",
        "start",
        "end",
        "center",
      ],
    };
  },
  created() {
    this.settings = { ...this.defaultSettings, cols: 3 };
  },
  methods: {
    chartSetting() {
      this.$emit("setSettings", JSON.parse(JSON.stringify(this.settings)));
    },
  },
};
</script>
<style lang="scss">
#donut-settings-container {
  .vue-swatches__trigger {
    height: 30px !important;
    width: 30px !important;
    box-shadow: 1px 1px 6px #aaa;
  }
  .v-text-field.v-text-field--solo .v-label {
    padding: 0 !important;
  }
  .v-text-field > .v-input__control > .v-input__slot {
    padding: 0;
    width: 100px;
  }
}
</style>