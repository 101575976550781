<template>
  <highcharts :options="chartOptions" />
</template>
<script>
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import WordCloud from 'highcharts/modules/wordcloud';

WordCloud(Highcharts);

export default {
  name: "WordCloudComponent",
  components: {
    highcharts: Chart
  },
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 150,
    },
    content: {
      type: String,
      default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum erat ac justo sollicitudin, quis lacinia ligula fringilla. Pellentesque hendrerit, nisi vitae posuere condimentum, lectus urna accumsan libero, rutrum commodo mi lacus pretium erat."
    },
    config: {
      type: Object,
      default: function () {
        return {
          isRandomColor: true,
          color: '#000000'
        }
      }
    }
  },
  data() {
    return {
      chartOptions: {}
    }
  },
  computed: {
    chartData() {
      var lines = this.content.split(/[,\. ]+/g);
      return Highcharts.reduce(lines, function (arr, word) {
        var obj = Highcharts.find(arr, function (obj) {
          return obj.name === word;
        });
        if (obj) {
          obj.weight += 1;
        } else {
          obj = {
              name: word,
              weight: 1
          };
          arr.push(obj);
        }
        return arr;
      }, []);
    }
  },
  mounted () {
    this.chartOptions = {
      chart: {
        height: this.height
      },
      series: [{
        type: 'wordcloud',
        data: this.chartData,
        name: 'Occurrences',
        color: this.config.color
      }],
      plotOptions: {
        wordcloud: {
          colorByPoint: this.config.isRandomColor
        }
      },
      title: {
        text: null
      },
      tooltip: { enabled: false },
      credits: {
        enabled: false
      }
    }
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    }
  }
}
</script>