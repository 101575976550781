<template>
  <doughnut-chart
    :chart-data="dataCollection"
    :options="options"
    :style="chartStyles"
  />
</template>

<script>
import DoughnutChart from "../config/DoughnutChart";

export default {
  name: "DonutComponent",
  components: {
    DoughnutChart,
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    },
    config: {
      type: Object,
      default: function () {
        return {
          showLegend: false,
          legendPosition: "right",
          labelsPosition: "center",
          labelsAlignment: "center",
          labelsColor: "white",
          showText: true,
        };
      },
    },
  },
  data() {
    return {
      dataCollection: {},
      options: {},
      chartStyles: {
        height: this.height,
        width: this.width,
      },
    };
  },
  mounted() {
    this.dataCollection = {
      datasets: [
        {
          backgroundColor: ["blue", "green", "red", "yellow"],
          data: [20, 25, 30, 25],
        },
      ],
      labels: ["Data 1", "Data 2", "Data 3", "Data 4"],
    };
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: this.config.showLegend,
        position: this.config.legendPosition,
        labels: {
          usePointStyle: true,
          boxWidth: 7,
        },
      },
      plugins: {
        datalabels: {
          anchor: this.config.labelsPosition,
          align: this.config.labelsAlignment,
          color: this.config.labelsColor,
        },
      },
    };
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    },
  },
};
</script>