import { Doughnut, mixins } from 'vue-chartjs';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins

export default {
    extends: Doughnut,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
        this.addPlugin(ChartJsPluginDataLabels);
        this.renderChart(this.chartData, this.options)
    }
}