<template>
  <highcharts :options="chartOptions" />
</template>
<script>
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import Funnel from 'highcharts/modules/funnel';
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

Funnel(Highcharts);

export default {
  name: "FunnelComponent",
  components: {
    highcharts: Chart
  },
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 150,
    },
    config: {
      type: [Object, Array],
      default: function () {
        return {
          showLegend: true,
          legendPosition: 'right',
        }
      }
    }
  },
  data() {
    return {
      chartOptions: {},
      align: null,
      verticalAlign: null,
      layout: null
    }
  },
  created() {
    switch (this.config.legendPosition) {
      case 'top':
        this.align = "center";
        this.verticalAlign = "top";
        this.layout = "horizontal";
        break;
      case 'bottom':
        this.align = "center";
        this.verticalAlign = "bottom";
        this.layout = "horizontal";
        break;
      case 'left':
        this.align = "left";
        this.verticalAlign = "top";
        this.layout = "verticle";
        break;
      case 'right':
        this.align = "right";
        this.verticalAlign = "top";
        this.layout = "verticle";
        break;
      default:
        this.align = "right";
        this.verticalAlign = "top";
        this.layout = "verticle";
        break;
    }
  },
  mounted () {
    this.chartOptions = {
      chart: {
        type: 'funnel',
        height: this.height
      },
      title: {
        text: null
      },
      legend: {
        enabled: this.config.showLegend,
        title: {
          text: 'Stage'
        },
        align: this.align,
        verticalAlign: this.verticalAlign,
        layout: this.layout,
        rtl: true,
        //reversed: true,
        itemDistance: 10,
        itemStyle: {
          fontWeight: 'normal',
          marginRight: 10
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
              enabled: true,
              color: '#FFFFFF',
              inside: true,
              //format: '<b>{point.name}</b> ({point.y:,.0f})',
              //connectorWidth: 0,
              style: {
                fontSize: '10px',
                fontWeight: 'normal'
              }
          },
          neckWidth: '15%',
          neckHeight: '0%',
          width: '35%',
          showInLegend: true
        }
      },
      series: [{
        name: null,
        data: [
          { name: 'Data 1', y: 100, color: '#518ff5' },
          { name: 'Data 2', y: 70, color: '#dc4860' },
          { name: 'Data 3', y: 60, color: '#7fe099' },
          { name: 'Data 4', y: 50, color: '#e9ae33' }
        ]
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                dataLabels: {
                  inside: true
                },
                center: ['50%', '50%'],
                width: '100%'
              }
            }
          }
        }]
      },
      tooltip: { enabled: false },
      credits: {
        enabled: false
      }
    }
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    }
  }
}
</script>