<template>
  <highcharts
    :constructor-type="'mapChart'"
    :options="mapOptions"
    class="map"
  />
</template>

<script>
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsMapModule  from 'highcharts/modules/map';
import mapData from '@highcharts/map-collection/custom/world.geo.json';
import proj4 from 'proj4';

Highcharts.win = Highcharts.win || {};
Highcharts.win.proj4 = proj4;

HighchartsMapModule(Highcharts);
Highcharts.maps['myMapName'] = mapData;

export default {
  name: "WorldMapComponent",
  components: {
    highcharts: Chart
  },
  props: {
    height: {
      type: Number,
      default: 150,
    }
  },
  data() {
    return {
      chartStyle: {
        height: this.height
      },
      mapOptions: {}
    }
  },
  mounted () {
    this.mapOptions = {
      chart: {
        map: 'myMapName',
        height: this.height
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      mapNavigation: {
        enabled: false,
        buttonOptions: {
          verticalAlign: 'bottom'
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Country',
        data: [
          ['us', 40],
          ['de', 120],
          ['se', 20],
          ['dk', 8],
          ['fi', 125]
        ],
        dataLabels: {
          enabled: false,
          color: '#FFFFFF',
          formatter: function () {
            if (this.point.value) {
              return this.point.name;
            }
          }
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '{point.value} Users in <b>{point.name}</b>'
        }
      }]
    }
  }
}
</script>