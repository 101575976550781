<template>
  <div class="data-chart">
    <div class="status-value">
      {{ getValuesData }}
      {{
        Object.keys(chart.reporting_data[0].report_data).includes('unit') ? chart.reporting_data[0].report_data.unit : ''
      }}
    </div>
    <div class="status-label">
      {{ chartTitle }}
    </div>
    <br>
  </div>
</template>
<script>
import BaseChart from "@/mixins/Chart";

export default {
  name: "SummaryComponent",
  mixins: [BaseChart],
  props: {
    chart: {
      type: Object,
      default: () => {
        return {
          data_model: {
            name: 'New Students'
          },
          reporting_data: [
            {
              report_data: {
                values: [
                  [23]
                ],
                labels: [
                  [
                    'Student Count'
                  ],
                ],
                unit: ''
              }
            }
          ]
        }
      }
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    },
    config: {
      type: Object,
      default: function () {
        return {
          isFill: false,
          textColor: "#7479db",
          labelColor: "#000000bf",
          showXaxisLabel: true,
          showXgridLines: true,
          showYaxisLabel: true,
          showYgridLines: true,
          showYscaleLabel: true,
          showLegend: false,
        };
      },
    }
  },
  computed: {
    getValuesData() {
      let response = 0;
      this.chart.reporting_data[0].report_data.values.forEach((item) => {
        console.log('Reducing?', item);
        response += item.reduce((a, b) => a + b, 0)
      })
      return response;
    }
  }
}
// Text Summaries
</script>
<style lang="scss" scoped>
.data-chart {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.status-value {
  font-family: Roboto;
  font-size: 64px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #7479db;
}

.status-label {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
}
</style>
