<template>
  <VContainer
    pa-0
    id="pie-settings-container"
  >
    <VRow class="pl-7 pr-7 pt-7">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Show Legend
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showLegend" />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </Vcontainer>
</template>
<script>

export default {
  name: "PieChartSettings",
  components: {
  },
  props: {
    defaultSettings: {
      type: Object,
      default: function () {
        return {
          cols: 4,
          showLegend: false,
        };
      },
    },
  },
  data() {
    return {
      settings: {
      },
    }
  },
  mounted() {
    console.log('Default settings', this.defaultSettings);
    this.settings = this.defaultSettings;
  },
  methods: {
    chartSetting() {
      console.log('chart settings')
      this.$emit('setSettings', JSON.parse(JSON.stringify(this.settings)));
    }
  },
  watch: {
    settings: {
      handler: function (newVal, oldVal) {
        console.log('New VAL', newVal);
        this.chartSetting();
      },
      deep: true
    }
  }
};
</script>
<style lang="scss">
#pie-settings-container {
  .vue-swatches__trigger {
    height: 30px !important;
    width: 30px !important;
    box-shadow: 1px 1px 6px #aaa;
  }
  .v-text-field.v-text-field--solo .v-label {
    padding: 0 !important;
  }
  .v-text-field > .v-input__control > .v-input__slot {
    padding: 0;
    width: 100px;
  }
}
</style>