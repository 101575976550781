<template>
  <VContainer
    pa-0
    id="funnel-settings-container"
  >
    <VRow class="pl-7 pr-7 pt-7">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions
            style="height: 40px;"
          >
            <VSubheader class="pa-2">
              Show Legend
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showLegend" />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        class="pr-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions
            style="height: 40px;"
          >
            <VSubheader class="pa-2">
              Legend Position
            </VSubheader>
            <VSpacer />
            <VCol class="pa-0">
              <VSelect
                :items="legendPositions"
                v-model="settings.legendPosition"
                dense
                solo
              />
            </VCol>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </Vcontainer>
</template>
<script>
import Swatches from "vue-swatches";

export default {
  name: 'FunnelSettings',
  components: {},
  props: {
    defaultSettings: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      settings: {},
      legendPositions: ['left', 'right', 'top', 'bottom']
    }
  },
  created() {
    this.settings = { ...this.defaultSettings, cols: 4 };
  },
  methods: {
    chartSetting() {
      this.$emit('setSettings', JSON.parse(JSON.stringify(this.settings)));
    }
  }
}
</script>
<style lang="scss">
#funnel-settings-container {
  .vue-swatches__trigger {
    height: 30px !important;
    width: 30px !important;
    box-shadow: 1px 1px 6px #aaa;
  }
  .v-text-field.v-text-field--solo .v-label {
    padding: 0 !important;
  }
  .v-text-field > .v-input__control > .v-input__slot {
    padding: 0;
    width: 100px;
  }
}
</style>