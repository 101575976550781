<script>
import BarChartComponent from "@/components/Reports/Charts/Bar/index";
import BarChartSettings from "@/components/Reports/Charts/Bar/Settings";

import DoughnutChartComponent from "@/components/Reports/Charts/Doughnut/index";
import DoughnutChartSettings from "@/components/Reports/Charts/Doughnut/Settings";

import WordCloudComponent from "@/components/Reports/Charts/WordCloud/index";
import WordCloudChartSettings from "@/components/Reports/Charts/WordCloud/Settings";

import WorldMapComponent from "@/components/Reports/Charts/WorldMap/index";
import HistogramComponent from "@/components/Reports/Charts/Histogram/index";
import HistogramSettings from "@/components/Reports/Charts/Histogram/Settings";

import PieChartComponent from "@/components/Reports/Charts/Pie/index";
import PieChartSettings from "@/components/Reports/Charts/Pie/Settings";

import DonutChartComponent from "@/components/Reports/Charts/Donut/index";
import DonutChartSettings from "@/components/Reports/Charts/Donut/Settings";

import UsersListComponent from "@/components/Reports/Charts/UsersList";

import FunnelChartComponent from "@/components/Reports/Charts/Funnel/index";
import FunnelChartSettings from "@/components/Reports/Charts/Funnel/Settings";

import TimerComponent from "@/components/Reports/Charts/Timer/index";
import TimerSettings from "@/components/Reports/Charts/Timer/Settings";

import ListBarComponent from "@/components/Reports/Charts/ListBar/index";
import ListBarSettings from "@/components/Reports/Charts/ListBar/Settings";

import SegmentChartComponent from "@/components/Reports/Charts/Segment/index";
import SegmentChartSettings from "@/components/Reports/Charts/Segment/Settings";

import LineChartComponent from "@/components/Reports/Charts/Line/index";
import LineChartSettings from "@/components/Reports/Charts/Line/Settings";

import FullMeterComponent from "@/components/Reports/Charts/FullMeter/index";
import FullMeterSettings from "@/components/Reports/Charts/FullMeter/Settings";

import RevenueChartComponent from "@/components/Reports/Charts/Revenue/index";
import RevenueChartSettings from "@/components/Reports/Charts/Revenue/Settings";

import RadialChartComponent from "@/components/Reports/Charts/Radial/index";
import RadialChartSettings from "@/components/Reports/Charts/Radial/Settings";

import SummaryComponent from "@/components/Reports/Charts/Summary/index";
import SummarySettings from "@/components/Reports/Charts/Summary/Settings";

import { mapActions, mapGetters } from 'vuex'

export default {
  events: false,
  components: {
    BarChartComponent,
    BarChartSettings,
    DoughnutChartComponent,
    DoughnutChartSettings,
    WordCloudComponent,
    WordCloudChartSettings,
    WorldMapComponent,
    HistogramComponent,
    HistogramSettings,
    PieChartComponent,
    PieChartSettings,
    DonutChartComponent,
    DonutChartSettings,
    UsersListComponent,
    FunnelChartComponent,
    FunnelChartSettings,
    TimerComponent,
    TimerSettings,
    ListBarComponent,
    ListBarSettings,
    SegmentChartComponent,
    SegmentChartSettings,
    LineChartComponent,
    LineChartSettings,
    FullMeterComponent,
    FullMeterSettings,
    RevenueChartComponent,
    RevenueChartSettings,
    RadialChartComponent,
    RadialChartSettings,
    SummaryComponent,
    SummarySettings
  },
  props: {
    activeChart: {
      type: Object,
      default: () => {},
    },
    dashboardId: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
    };
  },
  computed: {
    ...mapGetters({
      getCharts: 'reporting/data',
      getAllCharts: 'reporting/allData',
      getCategories: 'reporting/getCategories'
    }),
    resolvedActiveChart() {
      // TODO: fix this, needs to not exist here
      if (this.form.chart_type && typeof this.form.chart_type === 'object') {
        //eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.chartType = this.form.chart_type;
        //eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.chart_type = this.form.chart_type.name;
      }

      return {
        ...this.activeChart,
        ...(this.form || {}),
        ...{
          type: (this.form || {}).chart_type || this.activeChart.type,
          type_id: (this.form || {}).chart_type_id || this.activeChart.id
        },
      };
    },
    chartComponent() {
      return this.getChartComponentName(this.resolvedActiveChart.type);
    },
    chartComponentSettings() {
      return this.getChartComponentSettingsName(this.resolvedActiveChart.type);
    },
  },
  methods: {
    ...mapActions({
      listAllChartTypes: 'reporting/listChartTypes',
    }),
    getChartComponentName(type) {
      return `${type.titleize().replace(" ", "")}Component`;
    },
    getChartComponentSettingsName(type) {
      return `${type.titleize().replace(" ", "")}Settings`;
    },
    isSupportedChart(type) {
      return this.getAllCharts.findIndex((c) => c.name == type) > -1;
    },
    getChartsByCategory(category) {
      return category && Object.keys(this.getCategories).includes(category) ? this.getCategories[category] : Object.values(this.getCategories).flat();
    },
    normalizeChartRef(ref) {
      const normalized = ref
        .titleize()
        .replaceAll(/\s|Settings|Component/g, "");
      return normalized;
    },
    getDefaultConfigForRef(type) {
      let ref = this.$refs[this.normalizeChartRef(type)];
      this.$log.info(type, ref);
      try {
        return ref instanceof Array
          ? ref[0].getDefaultConfig()
          : ref.getDefaultConfig();
      } catch (error) {
        this.$log.warn(error);
        return {};
      }
    },
  },
  beforeMount() {
    if(this.getCharts.data.length === 0) {
      this.listAllChartTypes()
    }
  }
};
</script>