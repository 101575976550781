<template>
  <div />
</template>
<script>

export default {
  name: 'SummarySettings',
  props: {
    defaultSettings: {
      type: Object,
      default: function () {
        return {

        };
      }
    }
  },
  data() {
    return {
      settings: {},
      legendPositions: ['top', 'left', 'bottom', 'right'],
      labelsPosition: ['center', 'start', 'end'],
      labelsAlignment: ['top', 'left', 'bottom', 'right', 'start', 'end', 'center'],
    }
  },
  created() {
    this.settings = { ...this.defaultSettings, cols: 2 };
  },
  methods: {
    chartSetting() {
      this.$emit('setSettings', JSON.parse(JSON.stringify(this.settings)));
    }
  }
}
</script>
<style lang="scss">
#list-settings-container {
  .vue-swatches__trigger {
    height: 30px !important;
    width: 30px !important;
    box-shadow: 1px 1px 6px #aaa;
  }
  .v-text-field > .v-input__control > .v-input__slot {
    padding: 0;
    width: 100px;
  }
  .v-text-field.v-text-field--solo .v-label {
    padding: 0 !important;
  }
}
</style>