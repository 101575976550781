<template>
  <VRow>
    <VCol
      :style="chartStyles"
    >
      <VList>
        <VListItem  
          v-for="item in items"
          :key="item.message"
          class="pl-0 pr-0"
        >
          <VListItemAvatar
            :size="30"
          >
            <VImg
              :alt="`${item.title} avatar`"
              :src="item.avatar"
            />
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle 
              v-text="item.title" 
              style="font-size: 12px;"
            />
          </VListItemContent>
        </VListItem>
      </VList>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: "UsersListComponent",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    }
  },
  data() {
    return {
      chartStyles: {
        height: this.height,
        width: this.width,
        overflowY: "scroll",
      },
      items: [
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Johnny Rock' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Clara Attias' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Antony Floury' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Julian Oliver' },
      ]
    }
  },
}
</script>