<template>
  <VContainer
    style="height: 150px;"
  >
    <VRow
      no-gutters
    >
      <VCol md="6">
        <p 
          align="center"
          class="timer-data"
          :style="customStyle"
        >
          <strong>{{ days }}</strong>
        </p>
        <p 
          align="center"
          class="timer-label"
          :style="{ color: config.titleColor }"
        >
          DAYS
        </p>
      </VCol>
      <VCol md="6">
        <p 
          align="center"
          class="timer-data"
          :style="customStyle"
        >
          <strong>{{ hours }}</strong>
        </p>
        <p 
          align="center"
          class="timer-label"
          :style="{ color: config.titleColor }"
        >
          HOURS
        </p>
      </VCol>
    </VRow>
    <VRow>
      <VCol md="6">
        <p 
          align="center"
          class="timer-data"
          :style="customStyle"
        >
          <strong>{{ minutes }}</strong>
        </p>
        <p 
          align="center"
          class="timer-label"
          :style="{ color: config.titleColor }"
        >
          MINUTES
        </p>
      </VCol>
      <VCol md="6">
        <p 
          align="center"
          class="timer-data"
          :style="customStyle"
        >
          <strong>{{ seconds }}</strong>
        </p>
        <p 
          align="center"
          class="timer-label"
          :style="{ color: config.titleColor }"
        >
          SECONDS
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: "TimerComponent",
  props: {
    config: {
      type: Object,
      default: function () {
        return {
          countColor: '#7479db',
          titleColor: '#00000099',
        }
      }
    },
  },
  data() {
    return {
      days: 6,
      hours: 23,
      minutes: 56,
      seconds: 17,
      customStyle: {
        color: this.config.countColor,
      },
    }
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    }
  },
}
</script>
<style lang="scss">
.timer-data {
  font-size: 40px; 
  color: #518ff5; 
  margin-bottom: 5px !important;
}
.timer-label {
  font-size: 10px;
}
</style>