<template>
  <doughnut-chart 
    :chart-data="doughnutDataCollection"
    :styles="chartStyles"
    :options="doughnutOptions"
  />
</template>

<script>
import DoughnutChart from '../config/DoughnutChart';

export default {
  name: "DoughnutChartComponent",
  components: {
    DoughnutChart
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    },
    config: {
      type: Object,
      default: function () {
        return {
          showLegend: true,
          legendPosition: 'left',
        }
      }
    },
  },
  data() {
    return {
      doughnutDataCollection: {},
      doughnutOptions: {},
      chartStyles: {
        height: this.height,
        width: this.width,
      },
    }
  },
  mounted() {
    this.doughnutDataCollection = {
      datasets: [{
        backgroundColor: ['blue', 'green', 'red', 'yellow'],
        data: [10, 20, 30, 40]
      }],
      labels: [
          'Data 1',
          'Data 2',
          'Data 3',
          'Data 4',
      ]
    };
    this.doughnutOptions = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 85,
      labels: {
        render: 'label'
      },
      plugins: {
        datalabels: false
      },
      legend: {
        display: this.config.showLegend,
        position: this.config.legendPosition,
        labels: {
          usePointStyle: true,
          boxWidth: 7,
        }
      },
    }
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    }
  },
}
</script>