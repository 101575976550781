<template>
  <VContainer
    pa-0
    id="segment-settings-container"
  >
    <VRow no-gutters>
      <VCol>
        <VSubheader>X-axis</VSubheader>
      </VCol>
    </VRow>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions
            style="height: 40px;"
          >
            <VSubheader class="pa-2">
              Show axis label
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showXaxisLabel" />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        class="pr-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions
            style="height: 40px;"
          >
            <VSubheader class="pa-2">
              Show Gridlines
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showXgridLines" />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <div style="background-color: rgb(226 226 226)">
      <VRow no-gutters>
        <VCol>
          <VSubheader>Y-axis</VSubheader>
        </VCol>
      </VRow>
      <VRow class="pl-7 pr-7">
        <VCol
          md="6"
          pl-4
          class="pl-0 pt-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions
              style="height: 40px;"
            >
              <VSubheader class="pa-2">
                Show axis label
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showYaxisLabel" />
            </VCardActions>
          </VCard>
        </VCol>
        <VCol
          md="6"
          pl-4
          class="pr-0 pt-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions
              style="height: 40px;"
            >
              <VSubheader class="pa-2">
                Show Gridlines
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showYgridLines" />
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
      <VRow class="pl-7 pr-7">
        <VCol
          md="6"
          pl-4
          class="pl-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions
              style="height: 40px;"
            >
              <VSubheader class="pa-2">
                Show Legend
              </VSubheader>
              <VSpacer />
              <VSwitch 
                v-model="settings.showLegend" 
              />
            </VCardActions>
          </VCard>
        </VCol>
        <VCol
          md="6"
          pl-4
          class="pr-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions
              style="height: 40px;"
            >
              <VSubheader class="pa-2">
                Show Scale Label
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showYscaleLabel" />
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
    </div>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        pl-4
        class="pl-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions
            style="height: 40px;"
          >
            <VSubheader class="pa-2">
              Fill Color (Background)
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.isFill" />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        pl-4
        class="pr-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions
            style="height: 40px;"
          >
            <VSubheader class="pa-2">
              Background Color
            </VSubheader>
            <VSpacer />
            <Swatches
              v-model="settings.fillColor" 
              show-fallback
              swatches="text-advanced"
              class="pt-1"
            />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        pl-4
        class="pl-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions
            style="height: 40px;"
          >
            <VSubheader class="pa-2">
              Point Color
            </VSubheader>
            <VSpacer />
            <Swatches
              v-model="settings.pointColor" 
              show-fallback
              swatches="text-advanced"
              class="pt-1"
            />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </Vcontainer>
</template>
<script>
import Swatches from "vue-swatches";

export default {
  name: 'SegmentSettings',
  components: {
    Swatches
  },
  props: {
    defaultSettings: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      settings: {},
    }
  },
  created() {
    this.settings = { ...this.defaultSettings, cols: 4 };
  },
  methods: {
    chartSetting() {
      this.$emit('setSettings', JSON.parse(JSON.stringify(this.settings)));
    }
  }
}
</script>
<style lang="scss">
#segment-settings-container {
  .vue-swatches__trigger {
    height: 30px !important;
    width: 30px !important;
    box-shadow: 1px 1px 6px #aaa;
  }
  .v-text-field > .v-input__control > .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 2px !important;
  }
  .v-text-field.v-text-field--solo .v-label {
    padding: 0 !important;
  }
}
</style>