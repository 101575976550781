<template>
  <highcharts :options="chartOptions" />
</template>
<script>
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'
import bulletInit from 'highcharts/modules/bullet'

bulletInit(Highcharts);

export default {
  name: "RevenueChartComponent",
  components: {
    highcharts: Chart
  },
  props: {
    height: {
      type: Number,
      default: 150,
    },
    showLegend: {
      type: Boolean,
      default: false
    },
    minYaxis: {
      type: Number,
      default: 0
    },
    maxYaxis: {
      type: Number,
      default: 100
    },
    tickInerval: {
      type: Number,
      default: 20
    },
    bulletLength: {
      type: Number,
      default: 150
    },
    config: {
      type: Object,
      default: function () {
        return {
          showLegend: false,
          targetColor: "#ffffff",
          prefixYlabel: "$",
          postfixYlabel: "k",
          bulletColor: "#ffffff"
        }
      }
    }
  },
  data() {
    return {
      chartOptions: {}
    }
  },
  created() {
    this.config.prefixYlabel = this.config.prefixYlabel ? this.config.prefixYlabel : "";
    this.config.postfixYlabel = this.config.postfixYlabel ? this.config.postfixYlabel : "";
  },
  mounted () {
    this.chartOptions = {
      chart: {
        type: 'bullet',
        inverted: true,
        height: this.height
      },
      title: {
        text: null
      },
      legend: {
        enabled: this.config.showLegend
      },
      xAxis: {
        categories: [''],
      },
      yAxis: {
        plotBands: [{
          from: 0,
          to: 50,
          color: "#717fde"
        },{
          from: 50,
          to: 75,
          color: "#5794dc"
        },{
          from: 75,
          to: 9e9,
          color: "#91e4a7"
        }],
        labels: {
          format: this.config.prefixYlabel + '{value}' + this.config.postfixYlabel
        },
        title: null,
        gridLineWidth: 0,
        opposite: false,
        min: this.minYaxis,
        tickInterval: this.tickInerval,
        offset: 5,
        tickWidth: 1
      },
      series: [{
        type: "bullet",
        data: [{
          y : 70,
          target: 82
        }],
      }],
      plotOptions: {
        series: {
          pointPadding: 0.45,
          borderWidth: 0,
          borderRadius: 5,
          color: this.config.bulletColor,
          targetOptions: {
            width: '50',
            height: 1,
            color: this.config.targetColor
          }
        }
      },
      credits: {
        enabled: false
      }
    }
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    }
  }
};
</script>