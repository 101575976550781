<template>
  <VContainer
    pa-0
    id="fullmeter-settings-container"
  >
    <VRow class="pt-5 pl-7 pr-7">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Show Legend
            </VSubheader>
            <VSpacer />
            <VSwitch v-model="settings.showLegend" />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        class="pr-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Background Color
            </VSubheader>
            <VSpacer />
            <Swatches
              v-model="settings.plotColor"
              show-fallback
              swatches="text-advanced"
              class="pt-1"
            />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Meter Bar Color
            </VSubheader>
            <VSpacer />
            <Swatches
              v-model="settings.bulletColor"
              show-fallback
              swatches="text-advanced"
              class="pt-1"
            />
          </VCardActions>
        </VCard>
      </VCol>
      <VCol
        md="6"
        class="pr-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Label (Prefix)
            </VSubheader>
            <VSpacer />
            <VCol class="pa-0">
              <VTextField
                dense
                v-model="settings.prefixYlabel"
              />
            </VCol>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="pl-7 pr-7">
      <VCol
        md="6"
        class="pl-0 pt-0"
      >
        <VCard
          height="40px"
          rounded
        >
          <VCardActions style="height: 40px;">
            <VSubheader class="pa-2">
              Label (Postfix)
            </VSubheader>
            <VSpacer />
            <VCol class="pa-0">
              <VTextField
                dense
                v-model="settings.postfixYlabel"
              />
            </VCol>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </Vcontainer>
</template>
<script>
import Swatches from "vue-swatches";

export default {
  name: "FullMeterSettings",
  components: {
    Swatches,
  },
  props: {
    defaultSettings: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      settings: {},
    };
  },
  created() {
    this.settings = { ...this.defaultSettings, cols: 3 };
  },
  methods: {
    chartSetting() {
      this.$emit("setSettings", JSON.parse(JSON.stringify(this.settings)));
    },
  },
};
</script>
<style lang="scss">
#fullmeter-settings-container {
  .vue-swatches__trigger {
    height: 30px !important;
    width: 30px !important;
    box-shadow: 1px 1px 6px #aaa;
  }
  .v-text-field > .v-input__control > .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 2px !important;
  }
  .v-text-field.v-text-field--solo .v-label {
    padding: 0 !important;
  }
}
</style>